import { useRouter } from 'next/router'
import Layout from 'src/common/components/layout/Layout'
import {
  getHeader,
  getFooter,
  getMainNavigation,
  getAlertBanner,
  getPageData,
  getCustomBreadCrumbs,
} from 'src/common/services'
import { transformBreadCrumbData } from 'src/common/utils/js/transformBreadCrumbData'
import getComponent, { MODULES } from 'src/common/utils/js/getComponent'
import BlogPost from 'src/components/templates/blog-post/BlogPost'
import Event from 'src/components/templates/event/Event'
import NotFound from 'src/components/not-found/NotFound'

const Home = (props) => {
  const {
    headerData,
    footerData,
    mainNavigationData,
    alertBannerData,
    pageData,
    customBreadCrumbs,
  } = props

  const components = []
  const alertBanners = []

  const {
    meta_data: metaDeta,
    url,
    block,
    breadcrumb,
    blog = {},
    tribe_events = {},
    newsletter_mailing_id,
    enable_breadcrumb,
  } = pageData
  const isBlog = Object.keys(blog).length > 0
  const isEvent = Object.keys(tribe_events).length > 0

  if (block && Object.keys(block).length !== 0) {
    for (const [index, i] of Object.entries(block)) {
      const { acf_fc_layout: componentName, data } = i

      if (componentName) {
        const component = getComponent(componentName)
        if (component) {
          // For blog and event templates, if alert banner is configured to be the first module of the page, it needs to be shifted above the static header
          if (
            componentName === MODULES.ALERT_BANNER &&
            index === 0 &&
            (isBlog || isEvent)
          ) {
            alertBanners.push(component({ data, customBreadCrumbs } || null))
          } else {
            components.push(
              component(
                {
                  data,
                  order: +index + 1,
                  enable_breadcrumb,
                  breadcrumb: breadcrumb?.items,
                  customBreadCrumbs,
                } || null
              )
            )
          }
        }
      }
    }
  }

  const staticTemplate = () => {
    if (isBlog)
      return (
        <BlogPost
          data={{
            blog,
            enable_breadcrumb,
            customBreadCrumbs,
            breadcrumb: breadcrumb?.items,
          }}
        />
      )
    if (isEvent)
      return (
        <Event
          data={{
            tribe_events,
            enable_breadcrumb,
            customBreadCrumbs,
            breadcrumb: breadcrumb?.items,
          }}
        />
      )
    return null
  }

  const router = useRouter()
  const currentUrl = router.asPath
  const cleanCurrentUrl = currentUrl
    .split('#')[0]
    .split('?')[0]
    ?.replace(/\/$|\/#|#$/, '')

  const responseUrl = url?.replace(/\/$/, '')

  function isPageFound(res, curr) {
    if (isEvent) {
      res = res?.replace(/\/\d{4}-\d{2}-\d{2}\/?$/, '')
      curr = curr?.replace(/\/\d{4}-\d{2}-\d{2}\/?$/, '')
    }
    return res === curr
  }

  const pageFound = isPageFound(responseUrl, cleanCurrentUrl)

  return (
    <Layout
      headerData={headerData}
      footerData={footerData}
      customBreadCrumbs={customBreadCrumbs}
      mainNavigationData={mainNavigationData}
      alertBannerData={alertBannerData}
      newsletterMailingId={newsletter_mailing_id}
    >
      <div className="wrapper">
        {!pageFound && <NotFound />}
        {pageFound && (
          <>
            {alertBanners}
            {staticTemplate()}
            {components}
          </>
        )}
      </div>
    </Layout>
  )
}

export default Home

export async function getServerSideProps(context) {
  // eslint-disable-next-line global-require
  require('newrelic')

  let headerData = null
  let footerData = null
  let mainNavigationData = null
  let alertBannerData = null
  let customBreadCrumbs = []
  let pageData = []

  const { req, params, res } = context

  const referringDomain = req.headers?.host
  /**
   * Catch all the traffic coming from presidiotunneltops, presidiotrust and redirect them to their respective landing pages
   * Force Non WWW Urls from within node app
   */
  console.log({
    referringDomain,
    referer: req.headers?.referer,
  })

  if (referringDomain && referringDomain.includes('test.presidio.gov.')) {
    return {
      redirect: {
        destination: `${process.env.NEXT_PUBLIC_SERVER_URL}/explore/attractions/presidio-tunnel-tops`,
        permanent: false,
      },
    }
  }

  if (
    referringDomain &&
    (referringDomain.includes('presidiotunneltops.gov') ||
      referringDomain.includes('presidiotunneltops.org') ||
      referringDomain.includes('presidiotunneltops.com'))
  ) {
    return {
      redirect: {
        destination: `${process.env.NEXT_PUBLIC_SERVER_URL}/explore/attractions/presidio-tunnel-tops`,
        permanent: true,
      },
    }
  }
  if (referringDomain && referringDomain.includes('presidiotrust.gov')) {
    return {
      redirect: {
        destination: `${process.env.NEXT_PUBLIC_SERVER_URL}/about/presidio-trust`,
        permanent: true,
      },
    }
  }

  if (referringDomain.includes('www.')) {
    const nonWwwHost = referringDomain.replace(/^www\./, '')
    const nonWwwUrl = `https://${nonWwwHost}${req.url}`
    res.setHeader('Location', nonWwwUrl)
    res.statusCode = 301
    res.end()
  }

  let pathname

  if (!params || Object.keys(params).length === 0) pathname = 'home'
  else pathname = params?.slug?.join('/')

  try {
    const [
      getHeaderRes,
      getBreadCrumbsNamesRes,
      getFooterRes,
      getMainNavigationRes,
      getAlertBannerRes,
      getPageDataRes,
    ] = await Promise.allSettled([
      getHeader(),
      getCustomBreadCrumbs(),
      getFooter(),
      getMainNavigation(),
      getAlertBanner(),
      getPageData(pathname),
    ])

    if (getHeaderRes.status === 'fulfilled') {
      headerData = getHeaderRes.value
    } else {
      console.error(getHeaderRes)
    }

    if (getBreadCrumbsNamesRes.status === 'fulfilled') {
      customBreadCrumbs = transformBreadCrumbData(
        getBreadCrumbsNamesRes?.value?.custom_breadcrumb
      )
    } else {
      console.error(getBreadCrumbsNamesRes)
    }

    if (getFooterRes.status === 'fulfilled') {
      footerData = getFooterRes.value
    } else {
      console.error(getFooterRes)
    }

    if (getMainNavigationRes.status === 'fulfilled') {
      mainNavigationData = getMainNavigationRes.value
    } else {
      console.error(getMainNavigationRes)
    }

    if (getAlertBannerRes.status === 'fulfilled') {
      alertBannerData = getAlertBannerRes.value
    } else {
      console.error(getAlertBannerRes)
    }

    if (getPageDataRes.status === 'fulfilled') {
      pageData = getPageDataRes.value
    } else {
      console.error(getPageDataRes)
    }
  } catch (error) {
    console.error(error)
  }

  // Redirecting vanity URLs
  const { vanity_redirects: vanityRedirects } = footerData || {}

  let matchingRule

  if (
    vanityRedirects &&
    Array.isArray(vanityRedirects) &&
    vanityRedirects.length !== 0
  ) {
    matchingRule = vanityRedirects.find(
      (rule) => rule?.source?.toLowerCase() === `/${params.slug}`.toLowerCase()
    )
  }

  if (matchingRule) {
    const redirectStatus = matchingRule?.permanent ? 301 : 302

    res.writeHead(redirectStatus, { Location: matchingRule.destination })
    res.end()
    return { props: {} }
  }

  return {
    props: {
      headerData: headerData,
      footerData: footerData,
      customBreadCrumbs,
      mainNavigationData: mainNavigationData,
      alertBannerData: alertBannerData,
      pageData: pageData[0] || {},
    },
  }
}
